@charset "UTF-8";
html {
  font-size: 16px; }

body {
  font-size: .875rem; }

body {
  min-width: 1120px;
  overflow-x: hidden;
  font-size: 1.14286rem; }

.google-link:hover,
.txt-link:hover,
.meeting-ol a:hover,
.txt-tellink a:hover {
  text-decoration: none;
  color: #347eba; }

.l-max {
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  padding: 0; }

.l-contents {
  width: 1120px;
  margin: 0 auto;
  padding: 0; }

.l-outside {
  max-width: 1600px;
  min-width: 1120px;
  margin: 0 auto;
  padding: 0; }

.l-inside {
  width: 800px;
  margin: 0 auto;
  padding: 0; }

.l-login {
  width: 600px;
  margin: 0 auto;
  padding: 0; }

.l-960 {
  width: 960px;
  margin: 0 auto;
  padding: 0; }

.l-map {
  padding-bottom: 55.55%; }

.l-btn-1of2 {
  display: flex; }

.l-btn-1of2 a {
  flex: 0 0 350px;
  margin-right: 36px; }

.l-btn-1of2--small {
  width: 266px;
  margin-top: 20px; }

.l-btn-return {
  padding: 80px 0 0; }

.l-btn-caution {
  margin: 50px 0 70px; }

.l-form-btn {
  display: flex;
  width: 700px;
  margin: 70px auto 0; }

.l-form-btn button {
  width: 300px !important; }

.l-btn-map {
  justify-content: flex-start; }

.l-btn-map a {
  margin-left: 16px; }

.l-header {
  width: 1120px;
  margin: 0 auto;
  height: 140px;
  align-items: center; }

.l-footer {
  display: flex;
  flex-wrap: wrap;
  width: 710px;
  margin: 0 auto; }

.l-footer-infobtn {
  margin-top: 20px; }

.l-news-wrap {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.l-newslist-main {
  margin-right: 100px;
  width: 800px; }

.l-newslist-side {
  flex-shrink: 0;
  min-width: 300px;
  max-width: 300px; }

.l-btn-newsreturn {
  padding-top: 48px;
  padding-bottom: 80px; }

.l-news-link {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.l-corporate-link {
  padding: 0;
  margin: auto;
  width: 1370px; }

.l-flex-md {
  display: flex; }

.l-aligncenter-md {
  align-items: center; }

.l-wrap-md {
  flex-wrap: wrap; }

.l-gutter-md0 {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0; }

.l-gutter-md0 [class^=l-col-],
.l-gutter-md0 [class*=l-col-] {
  padding-left: 0;
  padding-right: 0; }

.l-gutter-md5 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2.5px;
  margin-right: -2.5px; }

.l-gutter-md5 [class^=l-col-],
.l-gutter-md5 [class*=l-col-] {
  padding-left: 2.5px;
  padding-right: 2.5px; }

.l-gutter-md10 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px; }

.l-gutter-md10 [class^=l-col-],
.l-gutter-md10 [class*=l-col-] {
  padding-left: 5px;
  padding-right: 5px; }

.l-gutter-md16 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px; }

.l-gutter-md16 [class^=l-col-],
.l-gutter-md16 [class*=l-col-] {
  padding-left: 8px;
  padding-right: 8px; }

.l-gutter-md20 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }

.l-gutter-md20 [class^=l-col-],
.l-gutter-md20 [class*=l-col-] {
  padding-left: 10px;
  padding-right: 10px; }

.l-gutter-md40 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px; }

.l-gutter-md40 [class^=l-col-],
.l-gutter-md40 [class*=l-col-] {
  padding-left: 20px;
  padding-right: 20px; }

.l-gutter-md50 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px; }

.l-gutter-md50 [class^=l-col-],
.l-gutter-md50 [class*=l-col-] {
  padding-left: 25px;
  padding-right: 25px; }

.l-col-md1,
.l-col-md2,
.l-col-md5of1,
.l-col-md7of1,
.l-col-md7of3,
.l-col-md3,
.l-col-md4,
.l-col-md5,
.l-col-md6,
.l-col-md7,
.l-col-md8,
.l-col-md9,
.l-col-md10,
.l-col-md11,
.l-col-md12,
.l-col-md-auto {
  position: relative;
  width: 100%;
  min-height: 1px; }

.l-col-md-auto {
  flex: 0 0 auto;
  max-width: 100%; }

.l-col-md12 {
  flex: 0 0 100%;
  max-width: 100%; }

.l-col-md11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.l-col-md10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.l-col-md9 {
  flex: 0 0 75%;
  max-width: 75%; }

.l-col-md8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.l-col-md7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.l-col-md6 {
  flex: 0 0 50%;
  max-width: 50%; }

.l-col-md5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.l-col-md4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.l-col-md3 {
  flex: 0 0 25%;
  max-width: 25%; }

.l-col-md5of1 {
  flex: 0 0 20%;
  max-width: 20%; }

.l-col-md2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.l-col-md1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

header {
  width: 100%;
  padding: 0;
  height: 140px;
  min-width: 1120px;
  transition: opacity .3s; }

header:before {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #92d192 0, #26c9ff 100%); }

.header-left {
  display: block;
  flex: 0 0 430px; }

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1;
  flex-basis: calc(100% - 430px);
  align-self: flex-start; }

.logo-header {
  background-image: url(../img/common/logo_pc.png);
  width: 376px;
  height: auto;
  transition: opacity .3s;
  margin-top: 15px; }

.logo-header:hover {
  opacity: .7; }

.logo-header a {
  height: auto; }

.menu-btn span.menu-txt:before {
  font-size: .625rem; }

.menu-btn {
  top: 0;
  right: 0;
  position: absolute;
  width: 90px;
  height: 90px; }

.menu-btn span {
  width: 40px;
  left: 25px; }

.menu-btn span:nth-of-type(1) {
  top: 24px; }

.menu-btn span:nth-of-type(2) {
  top: 34px; }

.menu-btn span:nth-of-type(3) {
  top: 44px; }

.menu-btn span.menu-txt {
  bottom: 38px; }

.menu-btn span.menu-txt:before {
  font-size: .85714rem; }

.open {
  background: transparent; }

.open span {
  color: #333; }

.open span:nth-of-type(1),
.open span:nth-of-type(3) {
  top: 34px;
  background-color: #333; }

.spmenu {
  flex: 0 0 100%;
  top: 0;
  padding: 0; }

.menu {
  padding: 0; }

.main-menu {
  display: flex; }

.main-menu-list,
.sub-menu-list {
  font-size: .9375rem; }

.main-menu-list,
.sub-menu-list {
  font-size: 1.28571rem;
  border: none;
  transition: color .3s;
  flex: 0 0 25%;
  text-align: center;
  padding: 10px 0;
  position: relative; }

.main-menu-list > a,
.sub-menu-list > a {
  padding: 30px 24px 3px;
  border-right: 1px solid #e5e5e5; }

.main-menu-list.about > a,
.about.sub-menu-list > a {
  border-left: 1px solid #e5e5e5; }

.main-menu-list:before,
.sub-menu-list:before {
  display: none; }

.main-menu-list:hover,
.sub-menu-list:hover {
  color: gray; }

.main-menu-list:hover:before,
.sub-menu-list:hover:before {
  opacity: .8; }

.main-menu-list > a:after,
.sub-menu-list > a:after {
  content: "";
  font-size: 0;
  border: 10px solid transparent;
  border-bottom-color: black;
  border-bottom-width: 16px;
  top: -26px;
  position: absolute;
  left: 50%;
  margin-left: -10px; }

.main-menu-list > a:after,
.sub-menu-list > a:after {
  top: 74px;
  visibility: hidden;
  opacity: 0;
  transition: visibility .3s,opacity .3s; }

.main-menu-list.about:before,
.about.sub-menu-list:before {
  content: "";
  height: 22px;
  width: 100%;
  display: block;
  background: url(../img/common/icon-about.png) no-repeat center top;
  background-size: contain;
  position: absolute;
  top: 24px;
  z-index: -2;
  left: 0;
  transition: opacity .3s; }

.main-menu-list.citizen:before,
.citizen.sub-menu-list:before {
  content: "";
  height: 22px;
  width: 100%;
  display: block;
  background: url(../img/common/icon-citizen.png) no-repeat center top;
  background-size: contain;
  position: absolute;
  top: 24px;
  z-index: -2;
  left: 0;
  transition: opacity .3s; }

.main-menu-list.doctor:before,
.doctor.sub-menu-list:before {
  content: "";
  height: 22px;
  width: 100%;
  display: block;
  background: url(../img/common/icon-doctor.png) no-repeat center top;
  background-size: contain;
  position: absolute;
  top: 24px;
  z-index: -2;
  left: 0;
  transition: opacity .3s; }

.main-menu-list.event:before,
.event.sub-menu-list:before {
  content: "";
  height: 22px;
  width: 100%;
  display: block;
  background: url(../img/common/icon-event.png) no-repeat center top;
  background-size: contain;
  position: absolute;
  top: 24px;
  z-index: -2;
  left: 0;
  transition: opacity .3s; }

.submenu {
  position: absolute;
  background: #fff;
  width: 100%;
  left: 50%;
  top: 76px;
  transform: translateX(-50%);
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: visibility .3s,opacity .3s;
  border: 4px solid;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 24px; }

.js_submenu:hover:after {
  content: "";
  width: 100%;
  height: 24px;
  display: block;
  position: absolute;
  bottom: -24px;
  border-top: 3px solid; }

.js_submenu:hover .submenu {
  visibility: visible;
  opacity: 1; }

.js_submenu:hover > a:after {
  visibility: visible;
  opacity: 1; }

.sub-menu-list {
  font-size: 1.14286rem;
  background: #fff;
  padding: 0 10px;
  letter-spacing: 0;
  transition: background .3s,color .3s;
  color: #333;
  text-align: left;
  position: relative; }

.sub-menu-list a {
  padding: 14px 10px 14px 28px;
  border-right: none;
  border-bottom: 1px solid #e5e5e5; }

.sub-menu-list a:before {
  content: "●";
  font-size: 12px;
  position: absolute;
  left: 16px;
  top: 18px; }

.about.main-menu-list:hover:after,
.about.sub-menu-list:hover:after {
  border-color: #015ea9; }

.about .submenu {
  border-color: #015ea9; }

.about > a:after {
  border-bottom-color: #015ea9; }

.about .sub-menu-list:hover {
  color: #015ea9; }

.about .sub-menu-list a:before {
  color: #015ea9; }

.about.active:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -3px;
  background: #015ea9; }

.citizen.main-menu-list:hover:after,
.citizen.sub-menu-list:hover:after {
  border-color: #1fbe88; }

.citizen .submenu {
  border-color: #1fbe88; }

.citizen > a:after {
  border-bottom-color: #1fbe88; }

.citizen .sub-menu-list:hover {
  color: #1fbe88; }

.citizen .sub-menu-list a:before {
  color: #1fbe88; }

.citizen.active:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -3px;
  background: #1fbe88; }

.doctor.main-menu-list:hover:after,
.doctor.sub-menu-list:hover:after {
  border-color: #0099cb; }

.doctor .submenu {
  border-color: #0099cb; }

.doctor > a:after {
  border-bottom-color: #0099cb; }

.doctor .sub-menu-list:hover {
  color: #0099cb; }

.doctor .sub-menu-list a:before {
  color: #0099cb; }

.doctor.active:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -3px;
  background: #0099cb; }

.event.main-menu-list:hover:after,
.event.sub-menu-list:hover:after {
  border-color: #549f46; }

.event .submenu {
  border-color: #549f46; }

.event > a:after {
  border-bottom-color: #549f46; }

.event .sub-menu-list:hover {
  color: #549f46; }

.event .sub-menu-list a:before {
  color: #549f46; }

.event.active:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -3px;
  background: #549f46; }

.about .sub-menu-list:hover {
  background: #f2f8fb; }

.citizen .sub-menu-list:hover {
  background: #fafaf3; }

.doctor .sub-menu-list:hover {
  background: #f3f8fa; }

.header-info h5 {
  font-size: .9375rem; }

.header-info-tel {
  font-size: 1.875rem; }

.header-info-tel {
  font-size: 1.85714rem; }

.header-info-tel {
  margin-top: 0; }

.header-info-note {
  font-size: .75rem; }

.header-info-em {
  font-size: .8125rem; }

.menu-title {
  font-size: 1.5rem; }

.txt-menu {
  font-size: .875rem; }

.page-main + footer {
  margin-top: 80px; }

.footer-contact {
  background-image: url(../img/common/contact-bg_pc.jpg);
  width: 1240px;
  margin: auto;
  height: 600px;
  background-position: bottom center;
  padding: 80px 0 220px; }

.footer-contact-area h2,
.page-contact-area h2 {
  font-size: 1.25rem; }

.footer-contact-area h4,
.page-contact-area h4 {
  font-size: .9375rem; }

.footer-contact-area,
.page-contact-area {
  width: 800px;
  margin: 0 auto;
  padding: 45px 24px 60px; }

.footer-contact-area h2,
.page-contact-area h2 {
  font-size: 1.85714rem; }

.footer-contact-area h4,
.page-contact-area h4 {
  font-size: 1.28571rem;
  margin-bottom: 5px; }

.footer-contact-tel {
  font-size: 1.875rem; }

.footer-contact-tel {
  font-size: 2.57143rem; }

.footer-contact-note {
  font-size: .75rem; }

.footer-contact-note {
  font-size: 1rem;
  margin-top: 3px;
  margin-bottom: 0; }

.footer-contct-wrap {
  display: flex;
  justify-content: center;
  margin-top: 25px; }

.footer-contct-wrap > div {
  padding: 0 40px;
  flex: 0 0 auto; }

.footer-contct-wrap > div:first-of-type {
  border-bottom: none;
  border-right: 1px solid #e5e5e5; }

.footer-info {
  padding: 60px 0 30px; }

.footer-info > div {
  width: 1120px;
  margin: 0 auto;
  display: flex; }

.footer-info .u-txt-em {
  margin-bottom: 2px; }

.logo-footer {
  background-image: url(../img/common/logo_pc.png);
  width: 425px;
  margin-right: 32px;
  margin-bottom: 0;
  aspect-ratio: 865 / 111; }

.footer-list-wrap {
  display: flex;
  width: 1120px;
  margin: 0 auto 40px; }

.footer-list {
  flex: 0 0;
  flex-basis: calc((100% - 200px)/4);
  list-style-type: none; }

.footer-list li {
  transition: color .3s; }

.footer-list li:hover {
  color: gray; }

.category-list li {
  font-size: .875rem; }

.category-list li:first-of-type {
  font-size: 1rem; }

.about.category-list li:first-of-type {
  transition: background .3s;
  background: #015ea9; }

.citizen.category-list li:first-of-type {
  transition: background .3s;
  background: #1fbe88; }

.doctor.category-list li:first-of-type {
  transition: background .3s;
  background: #0099cb; }

.event.category-list li:first-of-type {
  transition: background .3s;
  background: #549f46; }

.about.category-list li:first-of-type:hover {
  background: #347eba; }

.citizen.category-list li:first-of-type:hover {
  background: #41c89a; }

.doctor.category-list li:first-of-type:hover {
  background: #26a8d3; }

.event.category-list li:first-of-type:hover {
  background: #65a959; }

.copyright {
  font-size: .625rem; }

.copyright {
  font-size: .85714rem;
  margin: 0;
  padding: 12px 16px;
  text-align: center; }

.footer-link > div {
  padding: 30px 0;
  width: calc(1120px + 32px);
  margin: 0 auto; }

.footer-link a {
  flex: 0 0 25%;
  padding: 0 16px; }

.top-btn {
  cursor: pointer;
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px; }

.top-btn a {
  transition: opacity .3s; }

.top-btn a:hover {
  opacity: .8; }

.btn-fix {
  bottom: 55px; }

.index-main,
.page-main {
  margin-top: 140px; }

.contents-area {
  padding: 50px 0 0; }

.breadcrumb {
  font-size: .625rem; }

.breadcrumb {
  font-size: .85714rem;
  padding: 10px 0;
  margin: 0 auto 60px;
  height: auto;
  position: relative;
  width: 1120px; }

.breadcrumb ol {
  overflow-x: auto;
  white-space: auto;
  padding: 0;
  white-space: normal; }

.breadcrumb a {
  transition: color .3s; }

.breadcrumb a:hover {
  color: #71a5cf; }

.full-mv {
  margin-top: -100px;
  aspect-ratio: 32 / 13; }

.full-mv.roomplan {
  aspect-ratio: 4 / 1; }

.full-mv img {
  width: 100%; }

.btn-default {
  font-size: 1rem; }

.btn-default:before {
  font-size: .625rem; }

.btn-default {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.btn-default span {
  z-index: 2;
  position: relative; }

.btn-default:before {
  right: 16px;
  z-index: 2; }

.btn-default:hover,
.btn-default.hover {
  background: #347eba;
  color: #fff; }

.btn-default:hover:before,
.btn-default.hover:before {
  color: #fff; }

.btn-return {
  font-size: 1rem; }

.btn-return:before {
  font-size: .625rem; }

.btn-return {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.btn-return span {
  z-index: 2;
  position: relative; }

.btn-return:before {
  left: 16px;
  z-index: 2; }

.btn-return:hover,
.btn-return.hover {
  background: #868586;
  color: #fff; }

.btn-return:hover:before,
.btn-return.hover:before {
  color: #fff; }

.btn-return {
  min-width: 350px;
  padding: 14px 50px;
  margin: 0 auto; }

.btn-txt {
  font-size: .75rem; }

.btn-txt:before {
  font-size: .625rem; }

.btn-txt {
  font-size: 1rem;
  transition: color .3s; }

.btn-txt:hover {
  color: #347eba; }

.btn-header {
  font-size: 1rem; }

.btn-header:before {
  font-size: .625rem; }

.btn-header {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.btn-header span {
  z-index: 2;
  position: relative; }

.btn-header:before {
  right: 16px;
  z-index: 2; }

.btn-header:hover,
.btn-header.hover {
  background: #347eba;
  color: #fff; }

.btn-header:hover:before,
.btn-header.hover:before {
  color: #fff; }

.btn-board-meeting {
  font-size: 1rem; }

.btn-board-meeting:before {
  font-size: .625rem; }

.btn-board-meeting {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.btn-board-meeting span {
  z-index: 2;
  position: relative; }

.btn-board-meeting:before {
  left: 16px;
  z-index: 2; }

.btn-board-meeting:hover,
.btn-board-meeting.hover {
  background: #ff7191;
  color: #fff; }

.btn-board-meeting:hover:before,
.btn-board-meeting.hover:before {
  color: #fff; }

.btn-board-meeting {
  min-width: 280px;
  padding: 16px 20px 16px 40px; }

.btn-news {
  font-size: 1rem; }

.btn-news:before {
  font-size: .625rem; }

.btn-news {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.btn-news span {
  z-index: 2;
  position: relative; }

.btn-news:before {
  left: 16px;
  z-index: 2; }

.btn-news:hover,
.btn-news.hover {
  background: #41c89a;
  color: #fff; }

.btn-news:hover:before,
.btn-news.hover:before {
  color: #fff; }

.btn-news {
  width: 350px;
  min-width: 350px;
  margin: 25px auto 0; }

.btn-nozomi {
  font-size: 1rem; }

.btn-nozomi:before {
  font-size: .625rem; }

.btn-nozomi {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.btn-nozomi span {
  z-index: 2;
  position: relative; }

.btn-nozomi:before {
  right: 16px;
  z-index: 2; }

.btn-nozomi:hover,
.btn-nozomi.hover {
  background: #63b863;
  color: #fff; }

.btn-nozomi:hover:before,
.btn-nozomi.hover:before {
  color: #fff; }

.btn-doctor {
  font-size: 1rem; }

.btn-doctor:before {
  font-size: .625rem; }

.btn-doctor {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.btn-doctor span {
  z-index: 2;
  position: relative; }

.btn-doctor:before {
  right: 16px;
  z-index: 2; }

.btn-doctor:hover,
.btn-doctor.hover {
  background: #26a8d3;
  color: #fff; }

.btn-doctor:hover:before,
.btn-doctor.hover:before {
  color: #fff; }

.btn-newsletter {
  font-size: 1rem; }

.btn-newsletter:before {
  font-size: .625rem; }

.btn-newsletter {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.btn-newsletter span {
  z-index: 2;
  position: relative; }

.btn-newsletter:before {
  right: 16px;
  z-index: 2; }

.btn-newsletter:hover,
.btn-newsletter.hover {
  background: #f3f8fa;
  color: #015ea9; }

.btn-newsletter:hover:before,
.btn-newsletter.hover:before {
  color: #015ea9; }

.btn-newsletter {
  width: 100%;
  padding: 10px 16px;
  margin-bottom: 10px; }

.label {
  font-size: .625rem; }

.label {
  font-size: .85714rem;
  min-width: 110px;
  padding: 4px 16px;
  transition: opacity .3s,color .3s; }

.label-em,
.label.important {
  font-size: .625rem; }

.label-em,
.label.important {
  font-size: .85714rem;
  min-width: 110px;
  padding: 4px 16px;
  transition: opacity .3s,color .3s; }

.h-large {
  font-size: 1.125rem; }

.h-large {
  font-size: 1.71429rem; }

.h-large .eng {
  font-size: 1.42857rem;
  margin-top: 8px; }

.h-large--ul {
  font-size: 1.25rem; }

.h-large--ul {
  font-size: 1.85714rem;
  padding-bottom: 16px;
  margin-bottom: 25px; }

.h-large--normal {
  font-size: 1.25rem; }

.h-large--normal {
  font-size: 1.85714rem;
  margin-bottom: 16px; }

.h-middle {
  font-size: 1.25rem; }

.h-middle {
  font-size: 1.71429rem;
  margin-bottom: 16px; }

.h-middle--normal {
  font-size: 1.25rem; }

.h-middle--normal {
  font-size: 1.71429rem;
  margin-bottom: 16px; }

.h-middle--solid {
  font-size: 1rem; }

.h-middle--solid {
  margin-bottom: 16px;
  font-size: 1.42857rem; }

p + .h-middle--solid {
  margin-top: 30px; }

.h-solid {
  font-size: 1.5rem; }

.h-solid span {
  font-size: 1.125rem; }

.h-solid {
  font-size: 2.14286rem;
  text-align: left;
  padding: 16px 20px;
  margin: 0;
  border-radius: 8px; }

.h-solid span {
  display: inline;
  font-size: 1.71429rem; }

.h-index-news {
  font-size: 1.25rem; }

.h-index-news {
  font-size: 1.85714rem;
  padding: 16px; }

.h-index-news:before {
  width: 50px;
  height: 50px;
  left: calc(50% - 40px - 5em); }

.h-index-news:after {
  border-top-width: 10px;
  border-width: 10px;
  bottom: -19px; }

.h-privacy {
  font-size: 1.125rem; }

.h-privacy {
  font-size: 1.42857rem;
  padding-bottom: 10px;
  margin: 50px 0 16px; }

.h-privacy--middle {
  font-size: 1rem; }

.h-privacy--middle {
  font-size: 1.28571rem;
  margin: 30px 0 10px; }

.h-privacy + .h-privacy--middle {
  margin-top: 20px; }

.h-privacy--small {
  font-size: .875rem; }

.h-privacy--small {
  font-size: 1.14286rem;
  margin: 20px 0 8px; }

.pagetitle {
  padding: 45px 0 35px; }

.h-pagetitle {
  font-size: 1.1875rem; }

.h-pagetitle + p {
  font-size: .75rem; }

.h-pagetitle {
  font-size: 2.14286rem; }

.h-pagetitle + p {
  font-size: 1.28571rem;
  margin-top: 5px; }

.desc-txt {
  text-align: center; }

.note-txt {
  font-size: .75rem; }

.note-txt {
  font-size: 1rem; }

a.full-link ~ [class$=-img] img {
  transition: transform 0.5s cubic-bezier(0, 0, 0.1, 1); }

a.full-link:hover ~ [class$=-img] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

a.full-link:hover ~ [class$=-img] img,
a.full-link:hover ~ [class$=-img] video {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: scale(1.05); }

.sec-list {
  min-height: 470px; }

.list-row {
  margin-top: 50px;
  justify-content: center; }

.list-row.doctor {
  justify-content: flex-start;
  margin-top: 80px; }

.list-item span {
  font-size: 1.125rem; }

.list-item {
  margin: 0 0 30px;
  transition: color .3s; }

.list-item:hover {
  color: gray; }

.list-item span {
  font-size: 1.42857rem;
  padding: 20px 5px;
  background: #fff;
  position: relative;
  z-index: 2; }

.sec-category-page > div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.category-main {
  flex: 0 0 800px; }

.category-side {
  flex: 0 0 260px; }

.side-menu li {
  font-size: 1rem; }

.side-menu {
  margin-bottom: 20px; }

.side-menu li:before {
  top: 19px; }

.side-menu a {
  padding: 14px 16px; }

.category-btn {
  order: 3;
  flex: 0 0 100%; }

.table-default th,
.table-default td {
  padding: 16px 20px; }

.table-member th {
  width: 150px; }

img + .table-member {
  margin-top: 50px; }

.page-contact-area {
  border-width: 8px;
  padding: 30px 24px 40px; }

.page-contact-area.membership {
  margin-top: 40px;
  padding: 25px 24px 35px; }

.login-title {
  height: 100px; }

.login-title a {
  width: 430px; }

.login-main {
  min-height: calc(100vh - 100px); }

.h-login {
  font-size: 1.5rem; }

.h-login {
  font-size: 2.14286rem;
  margin-bottom: 25px; }

.login-area {
  width: 300px;
  margin: 25px auto 0; }

.login-input {
  width: 300px !important;
  height: 45px !important;
  padding: 5px 10px !important; }

.login-label {
  font-size: .875rem; }

.login-label {
  font-size: 1.14286rem; }

button.form-btn--login {
  font-size: 1rem; }

button.form-btn--login:before {
  font-size: .625rem; }

button.form-btn--login {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

button.form-btn--login span {
  z-index: 2;
  position: relative; }

button.form-btn--login:before {
  right: 16px;
  z-index: 2; }

button.form-btn--login:hover,
button.form-btn--login.hover {
  background: #f16565;
  color: #fff; }

button.form-btn--login:hover:before,
button.form-btn--login.hover:before {
  color: #fff; }

button.form-btn--login {
  min-width: 200px;
  width: 200px;
  margin: auto; }

.l-btn--login {
  min-width: 200px;
  width: 200px;
  margin-top: 40px; }

.login-error {
  font-size: 1rem; }

.index-mv {
  display: flex;
  max-height: 700px;
  min-height: 600px; }

.index-mv-img {
  flex: 0 0 56.25%; }

.index-mv-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.index-mv-data .caption {
  font-size: .625rem; }

.index-mv-data h2 {
  font-size: 1.3125rem; }

.index-mv-data {
  flex: 0 0 43.75%;
  padding: 5vw 50px 0; }

.index-mv-data .caption {
  font-size: 1.14286rem; }

.index-mv-data h2 {
  font-size: 2.42857rem;
  margin: 20px 0; }

.index-mv-data:before {
  top: auto;
  bottom: 16px;
  left: 480px;
  transform: translateX(-100%);
  width: 20vw;
  max-width: 442px;
  min-width: 340px; }

.index-main-copy {
  width: 445px; }

.sec-index-news {
  padding: 50px 0 80px; }

.index-bnr-wrap {
  display: flex;
  margin: 0 -16px 50px; }

.index-bnr-wrap a {
  flex: 0 0 50%;
  padding: 0 16px;
  margin: 0; }

.sec-index-contents {
  padding: 60px 0 70px; }

.sec-index-contents > div {
  padding: 0;
  margin: auto;
  width: 1240px; }

.index-contents-copy {
  font-size: 1.42857rem;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
  line-height: 1.5; }

.index-contents-item h3 {
  font-size: 1.25rem; }

.index-contents-item p {
  font-size: .8125rem; }

.index-contents-item {
  margin-top: 40px;
  height: 780px; }

.index-contents-item h3 {
  font-size: 1.85714rem;
  margin: 20px 0 10px; }

.index-contents-item p {
  font-size: 1rem;
  margin: 0; }

.index-contents-item.event {
  height: auto !important;
  display: flex;
  padding: 0; }

.index-contents-item.event h3 {
  text-align: left;
  padding-left: 70px;
  margin-top: 25px; }

.index-contents-item.event p {
  text-align: left;
  padding-left: 70px; }

.index-contents-item.event li {
  padding-left: 70px;
  margin-top: 20px; }

.index-contents-item.event img {
  width: 400px; }

.index-contents-list a {
  font-size: 1rem; }

.index-contents-list a:before {
  font-size: .625rem; }

.index-contents-list a {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.index-contents-list a span {
  z-index: 2;
  position: relative; }

.index-contents-list a:before {
  right: 16px;
  z-index: 2; }

.index-contents-list a:hover,
.index-contents-list a.hover {
  background: #347eba;
  color: #fff; }

.index-contents-list a:hover:before,
.index-contents-list a.hover:before {
  color: #fff; }

.index-contents-list li {
  margin-bottom: 14px;
  padding: 0 50px; }

.index-contents-list a {
  padding: 12px 20px; }

.h-message-title {
  font-size: 1.4375rem; }

.h-message-title {
  font-size: 2.28571rem;
  margin: 0 0 30px; }

.message-txt {
  margin-top: 30px; }

.message-wrap {
  display: flex;
  align-items: center; }

.message-img {
  flex: 0 0 300px;
  order: 2; }

.overview-map {
  margin: 50px auto;
  width: 600px; }

.table-overview {
  margin-top: 30px; }

.table-overview th {
  width: 150px; }

.table-commission th {
  width: 320px; }

.table-commission td:nth-of-type(1) {
  width: 90px; }

.member-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 60px; }

.member-list li {
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 8px;
  flex: 0 0 50%; }

.member-list a {
  transition: color .3s,background .3s; }

.member-list a:hover {
  color: #347eba;
  background: #f2f8fb; }

.nozomi-insta {
  margin: 60px 0 30px; }

.h-nozomi {
  font-size: 1.25rem; }

.h-nozomi {
  font-size: 2rem;
  margin-bottom: 25px; }

.h-nozomi-solid {
  font-size: 1rem; }

.h-nozomi-solid {
  font-size: 1.57143rem;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 40px 0 30px; }

.nozomi-link li {
  flex: 0 0 33.333333%;
  padding: 0 5px;
  justify-content: center;
  margin-bottom: 10px; }

.nozomi-link a {
  padding: 10px;
  border-width: 4px;
  min-height: 50px;
  border-radius: 10px;
  transition: color .3s,background .3s; }

.nozomi-link a:hover {
  background: #eff9ef;
  color: #47ab47; }

.sec-nozomi {
  margin-top: 80px; }

.nozomi-feature span {
  font-size: 1.125rem; }

.nozomi-feature {
  padding-left: 140px;
  min-height: 50px;
  display: flex;
  align-items: center; }

.nozomi-feature span {
  font-size: 1.57143rem;
  width: 110px; }

.nozomi-list {
  flex: 0 0 400px;
  padding: 0 20px; }

.nozomi-service-row {
  display: flex; }

.nozomi-service-img {
  margin: 0;
  flex: 0 0 400px; }

.sec-membership {
  margin-top: 60px; }

.merit-row {
  margin-bottom: -16px; }

.merit-item h5 {
  font-size: .875rem; }

.merit-item {
  display: flex; }

.merit-item > div {
  display: block;
  flex-direction: column;
  margin-bottom: 16px;
  flex: 1 0 50%; }

.merit-item h5 {
  font-size: 1.14286rem;
  padding: 8px; }

.merit-item p {
  padding: 20px 10px;
  text-align: center; }

.membership-move-img {
  margin-top: 20px;
  width: 420px; }

.table-membership th {
  width: 150px;
  padding: 10px 20px; }

.table-membership td {
  padding: 10px 20px;
  height: 60px; }

.feature-link {
  margin-top: 80px;
  border-width: 8px;
  display: flex; }

.feature-link-img {
  order: 2;
  flex: 0 0 340px; }

.feature-link-data {
  flex: 0 0;
  flex-basis: calc(100% - 340px);
  text-align: left;
  padding: 24px 0 24px 40px; }

.feature-slide {
  width: 800px; }

.future-map-row {
  display: flex;
  align-items: flex-start;
  margin-top: 30px; }

.future-map-row > P {
  flex: 500px 1;
  padding-right: 30px; }

.future-map {
  flex: 0 0 300px;
  width: 300px;
  margin: 0; }

.sec-voice {
  margin-top: 50px; }

.voice-row {
  margin-top: 60px;
  display: flex;
  justify-content: space-between; }

.voice-row:nth-of-type(odd) {
  flex-direction: row-reverse; }

.voice-row:first-of-type {
  margin-top: 0; }

.voice-img {
  flex: 0 0 300px; }

.voice-data {
  flex: 0 0 470px; }

.h-voice {
  font-size: 1.25rem; }

.h-voice {
  font-size: 1.85714rem;
  margin-bottom: 12px; }

.contact-recruitment-txt {
  font-size: .9375rem; }

.contact-recruitment-txt {
  font-size: 1.28571rem;
  margin-bottom: 20px; }

.contact-recruitment-txt + a {
  margin: auto; }

.googlemap {
  margin-top: 20px; }

.contact-tel {
  font-size: 1.5625rem; }

.contact-tel {
  font-size: 2.28571rem; }

.contact-tel {
  display: inline-block; }

.contact-caution {
  font-size: .75rem; }

.contact-caution {
  font-size: 1rem; }

.contact-readme {
  font-size: .75rem; }

.contact-readme {
  font-size: 1rem;
  padding: 16px 20px; }

a.sitemap {
  font-size: 1.28571rem;
  padding: 12px 20px;
  margin-top: 25px;
  transition: color .3s; }

a.sitemap:hover {
  color: #015ea9; }

a.sitemap--sub {
  display: table;
  margin-top: 16px;
  transition: color .3s; }

a.sitemap--sub:hover {
  color: gray; }

.index-news-wrap {
  padding-bottom: 30px;
  margin: 30px 0 0; }

.news-title time {
  font-size: .6875rem; }

.news-title {
  position: relative;
  padding: 16px 0 16px 9em;
  margin: 0 20px;
  transition: color .3s; }

.news-title time {
  font-size: 1rem;
  display: inline-block;
  position: absolute;
  left: 0;
  margin: 2px 0 0; }

.news-title:hover {
  color: gray; }

.index-news-em {
  font-size: .75rem; }

.index-news-em {
  font-size: 1rem; }

.news-list-area {
  margin: 50px 0 60px; }

.news-list-area .btn-news {
  margin-top: 30px; }

.news-main-time {
  font-size: .6875rem; }

.news-main-time {
  font-size: 1rem;
  display: inline-block;
  margin: 0 0 10px; }

p.news-main-txt {
  line-height: 2; }

.h-small-program {
  font-size: .875rem; }

.h-small-program {
  font-size: 1.14286rem;
  margin: 40px 0 30px; }

.h-small-meeting {
  font-size: .875rem; }

.h-small-meeting {
  font-size: 1.14286rem;
  margin: 0 0 20px; }

.meeting-ol li {
  margin-bottom: 10px; }

.meeting-ol p {
  margin-top: 10px; }

.sec-event {
  min-height: 250px; }

.event-list-row {
  margin: 0; }

.event-list-row a {
  display: flex;
  padding: 30px 0; }

.event-list-row:first-of-type {
  margin-top: 60px; }

.event-list-img {
  margin: 0;
  flex: 0 0 256px; }

.event-list-data {
  padding-left: 32px; }

span[class*=label--] {
  font-size: .75rem; }

span[class*=label--] {
  font-size: 1rem; }

.event-list-title {
  font-size: 1rem; }

.event-list-title {
  font-size: 1.42857rem;
  margin: 16px 0; }

.radio-wrap {
  margin-top: 8px; }

.category .wpcf7-list-item-label {
  padding: .1em 25px .1em 3px; }

.login-input,
.contact-form input[type=text],
.contact-form input[type=tel],
.contact-form input[type=address],
.contact-form input[type=email],
.contact-form select,
.wpcf7-form input[type=text],
.wpcf7-form input[type=tel],
.wpcf7-form input[type=address],
.wpcf7-form input[type=email],
.wpcf7-form select,
.contact-form textarea,
.wpcf7-form textarea,
.contact-form input[name=age],
.wpcf7-form input[name=age] {
  font-size: 1rem; }

.login-input,
.contact-form input[type=text],
.contact-form input[type=tel],
.contact-form input[type=address],
.contact-form input[type=email],
.contact-form select,
.wpcf7-form input[type=text],
.wpcf7-form input[type=tel],
.wpcf7-form input[type=address],
.wpcf7-form input[type=email],
.wpcf7-form select,
.contact-form textarea,
.wpcf7-form textarea,
.contact-form input[name=age],
.wpcf7-form input[name=age] {
  margin: 0;
  padding: 10px 16px;
  height: 50px;
  width: 400px; }

.contact-form input[name=email],
.contact-form input[name=address],
.wpcf7-form input[name=email],
.wpcf7-form input[name=address] {
  width: 500px; }

.contact-form textarea.free-txt,
.wpcf7-form textarea.free-txt,
.contact-form textarea[name=free-txt],
.wpcf7-form textarea[name=free-txt] {
  width: 500px; }

.contact-form input[name=age],
.wpcf7-form input[name=age] {
  width: 100px; }

:-ms-input-placeholder {
  font-size: .875rem; }

.error-message label,
.error-message span {
  margin-top: 5px;
  padding: 2px 10px; }

.form-tbl .th {
  font-size: .875rem; }

.form-tbl .must:before,
.form-tbl .optional:before {
  font-size: .75rem; }

.form-tbl {
  width: 800px;
  margin: 40px 0 0;
  display: block;
  border: none;
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5; }

.form-tbl .tr {
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #e5e5e5;
  min-height: 80px; }

.form-tbl .th {
  font-size: 1.14286rem;
  padding: 20px;
  padding-left: 70px;
  width: 240px;
  display: flex;
  align-items: center; }

.form-tbl .td {
  width: calc(800px - 240px);
  padding: 20px 0 20px 40px; }

.form-tbl .must:before,
.form-tbl .optional:before {
  font-size: .85714rem;
  left: 20px;
  padding: 0 10px; }

.form-tbl .tr:last-of-type .th {
  padding-top: 14px; }

.form-tbl--conf .tr {
  min-height: 55px; }

.form-tbl--conf .th {
  padding: 16px 20px; }

.form-tbl--conf .td {
  padding: 16px 20px; }

.form-note {
  font-size: .75rem; }

.form-note {
  font-size: 1rem;
  margin-top: 10px; }

.form-contact {
  margin-bottom: 90px; }

.form-conf {
  font-size: 1rem; }

.form-conf.must:before {
  font-size: .75rem; }

.form-conf {
  font-size: 1.42857rem;
  margin: 50px 0 0; }

.h-privacy-policy {
  font-size: .9375rem; }

.h-privacy-policy {
  font-size: 1.28571rem; }

button.form-btn--check {
  font-size: 1rem; }

button.form-btn--check:before {
  font-size: .625rem; }

button.form-btn--check {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

button.form-btn--check span {
  z-index: 2;
  position: relative; }

button.form-btn--check:before {
  right: 16px;
  z-index: 2; }

button.form-btn--check:hover,
button.form-btn--check.hover {
  background: #f16565;
  color: #fff; }

button.form-btn--check:hover:before,
button.form-btn--check.hover:before {
  color: #fff; }

button.form-btn--check {
  font-size: 1.125rem; }

button.form-btn--check {
  font-size: 1.42857rem; }

button.form-btn--return {
  font-size: 1rem; }

button.form-btn--return:before {
  font-size: .625rem; }

button.form-btn--return {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

button.form-btn--return span {
  z-index: 2;
  position: relative; }

button.form-btn--return:before {
  right: 16px;
  z-index: 2; }

button.form-btn--return:hover,
button.form-btn--return.hover {
  background: #bababa;
  color: #fff; }

button.form-btn--return:hover:before,
button.form-btn--return.hover:before {
  color: #fff; }

button.form-btn--return {
  font-size: 1.125rem; }

button.form-btn--return {
  font-size: 1.42857rem;
  flex: 0 0 350px;
  width: 350px;
  margin-bottom: 0;
  padding: 20px; }

button.form-btn--submit {
  font-size: 1rem; }

button.form-btn--submit:before {
  font-size: .625rem; }

button.form-btn--submit {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

button.form-btn--submit span {
  z-index: 2;
  position: relative; }

button.form-btn--submit:before {
  right: 16px;
  z-index: 2; }

button.form-btn--submit:hover,
button.form-btn--submit.hover {
  background: #f16565;
  color: #fff; }

button.form-btn--submit:hover:before,
button.form-btn--submit.hover:before {
  color: #fff; }

button.form-btn--submit {
  font-size: 1.125rem; }

button.form-btn--submit {
  font-size: 1.42857rem;
  flex: 0 0 350px;
  width: 350px;
  margin-bottom: 0;
  padding: 20px; }

.l-btn--submit:before {
  font-size: .625rem; }

.l-btn--submit {
  width: 350px;
  margin: 40px auto 0; }

.l-btn--submit:before {
  font-size: .85714rem;
  right: 20px; }

.l-btn_1of2--submit {
  width: 720px;
  margin: 60px auto 0;
  display: flex;
  justify-content: space-between; }

.wrap-btn-submit:before {
  font-size: .625rem; }

.wrap-btn-submit:before {
  right: 20px; }

.wrap-btn-return:before {
  font-size: .625rem; }

.btn-contact {
  font-size: 1rem; }

.btn-contact:before {
  font-size: .625rem; }

.btn-contact {
  cursor: pointer;
  display: table;
  margin: 0;
  min-width: 300px;
  font-size: 1.28571rem;
  padding: 14px 20px;
  transition: color .3s,background .3s; }

.btn-contact span {
  z-index: 2;
  position: relative; }

.btn-contact:before {
  right: 16px;
  z-index: 2; }

.btn-contact:hover,
.btn-contact.hover {
  background: #347eba;
  color: #fff; }

.btn-contact:hover:before,
.btn-contact.hover:before {
  color: #fff; }

.btn-contact {
  margin: 24px auto 40px; }

.contact-agreement h4 {
  font-size: .9375rem; }

.contact-agreement {
  border-width: 2px;
  width: 800px;
  margin: 0 auto 30px;
  padding: 0 40px 30px; }

.contact-agreement h4 {
  font-size: 1.14286rem;
  padding: 16px;
  margin: 0 -40px; }

.contact-agreement .default-txt {
  padding-top: 20px; }

.contact-agreement input[type=text] {
  width: 300px;
  margin-left: 20px; }

.contact-agreement input[type=checkbox] {
  margin-left: 20px; }

.form-select-td select {
  font-size: 1rem; }

.form-select-td select {
  height: 50px;
  width: 450px; }

.wrap-select:before {
  font-size: .625rem; }

.wrap-select {
  width: 470px;
  height: 50px; }

.wrap-select .error-message {
  top: -12px; }

.wrap-select .error-message + select {
  margin-top: 30px; }

.wrap-select.adjust:before {
  top: calc(50% + 12px); }

.form-jobname {
  display: flex;
  align-items: center;
  height: 50px; }

.h-questionnaire--balloon {
  font-size: 1rem; }

.h-questionnaire--balloon {
  font-size: 2.14286rem;
  padding: 12px;
  margin-bottom: 30px; }

.h-questionnaire--balloon:after {
  content: "";
  font-size: 0;
  border: 15px solid transparent;
  border-top-color: #aaae00;
  border-top-width: 16px;
  bottom: -31px;
  position: absolute;
  left: 50%;
  margin-left: -15px; }

.enquete-merit {
  margin-bottom: 40px; }

.btn-questionnaire-wrap input + span {
  font-size: 1rem; }

.btn-questionnaire-wrap input + span {
  font-size: 1.85714rem;
  padding: 12px;
  transition: background .3s;
  width: 600px;
  margin: 0 auto; }

.btn-questionnaire-wrap input + span:hover {
  background: #b9bd00;
  cursor: pointer; }

.btn-questionnaire-wrap {
  margin-bottom: 50px; }

.h-enquete {
  font-size: 1.375rem; }

.h-enquete {
  font-size: 2.28571rem;
  padding: 12px;
  margin: 0 auto 20px;
  width: 100%; }

#is_check_questionnaire .h-enquete {
  margin-top: 40px; }

.enquete-cap {
  font-size: 1rem; }

.enquete-cap {
  font-size: 1.42857rem;
  margin: 70px 0 20px; }

.trigger-wrap {
  width: 400px;
  margin-top: 10px; }

.form-event-name {
  font-size: .875rem; }

.member_size-wrap {
  max-width: 300px; }

.member_size-wrap input[type=text] {
  width: 150px;
  margin: 0 1em 5px 0; }

.checkbox-flex input[type=text] {
  height: 40px; }

.unit-input select {
  width: 200px; }

.wpcf7-form textarea[name=hoop12_house_text] {
  height: 150px; }

.form-caution {
  font-size: 1rem; }

.form-caution {
  font-size: 1.42857rem;
  padding: 20px;
  width: 1120px;
  margin: 60px auto 50px; }

.ui-datepicker {
  transform: translateY(-90px); }

.u-txt-s {
  font-size: 1rem; }

.pc-none {
  display: none; }

.u-mt0 {
  margin-top: 0 !important; }

.u-mb0 {
  margin-bottom: 0 !important; }

.u-ml0 {
  margin-left: 0 !important; }

.u-mr0 {
  margin-right: 0 !important; }

.u-pt0 {
  padding-top: 0 !important; }

.u-pb0 {
  padding-bottom: 0 !important; }

.u-pl0 {
  padding-left: 0 !important; }

.u-pr0 {
  padding-right: 0 !important; }

.u-mt5 {
  margin-top: 5px !important; }

.u-mb5 {
  margin-bottom: 5px !important; }

.u-ml5 {
  margin-left: 5px !important; }

.u-mr5 {
  margin-right: 5px !important; }

.u-pt5 {
  padding-top: 5px !important; }

.u-pb5 {
  padding-bottom: 5px !important; }

.u-pl5 {
  padding-left: 5px !important; }

.u-pr5 {
  padding-right: 5px !important; }

.u-mt10 {
  margin-top: 10px !important; }

.u-mb10 {
  margin-bottom: 10px !important; }

.u-ml10 {
  margin-left: 10px !important; }

.u-mr10 {
  margin-right: 10px !important; }

.u-pt10 {
  padding-top: 10px !important; }

.u-pb10 {
  padding-bottom: 10px !important; }

.u-pl10 {
  padding-left: 10px !important; }

.u-pr10 {
  padding-right: 10px !important; }

.u-mt15 {
  margin-top: 15px !important; }

.u-mb15 {
  margin-bottom: 15px !important; }

.u-ml15 {
  margin-left: 15px !important; }

.u-mr15 {
  margin-right: 15px !important; }

.u-pt15 {
  padding-top: 15px !important; }

.u-pb15 {
  padding-bottom: 15px !important; }

.u-pl15 {
  padding-left: 15px !important; }

.u-pr15 {
  padding-right: 15px !important; }

.u-mt20 {
  margin-top: 20px !important; }

.u-mb20 {
  margin-bottom: 20px !important; }

.u-ml20 {
  margin-left: 20px !important; }

.u-mr20 {
  margin-right: 20px !important; }

.u-pt20 {
  padding-top: 20px !important; }

.u-pb20 {
  padding-bottom: 20px !important; }

.u-pl20 {
  padding-left: 20px !important; }

.u-pr20 {
  padding-right: 20px !important; }

.u-mt25 {
  margin-top: 25px !important; }

.u-mb25 {
  margin-bottom: 25px !important; }

.u-ml25 {
  margin-left: 25px !important; }

.u-mr25 {
  margin-right: 25px !important; }

.u-pt25 {
  padding-top: 25px !important; }

.u-pb25 {
  padding-bottom: 25px !important; }

.u-pl25 {
  padding-left: 25px !important; }

.u-pr25 {
  padding-right: 25px !important; }

.u-mt30 {
  margin-top: 30px !important; }

.u-mb30 {
  margin-bottom: 30px !important; }

.u-ml30 {
  margin-left: 30px !important; }

.u-mr30 {
  margin-right: 30px !important; }

.u-pt30 {
  padding-top: 30px !important; }

.u-pb30 {
  padding-bottom: 30px !important; }

.u-pl30 {
  padding-left: 30px !important; }

.u-pr30 {
  padding-right: 30px !important; }

.u-mt35 {
  margin-top: 35px !important; }

.u-mb35 {
  margin-bottom: 35px !important; }

.u-ml35 {
  margin-left: 35px !important; }

.u-mr35 {
  margin-right: 35px !important; }

.u-pt35 {
  padding-top: 35px !important; }

.u-pb35 {
  padding-bottom: 35px !important; }

.u-pl35 {
  padding-left: 35px !important; }

.u-pr35 {
  padding-right: 35px !important; }

.u-mt40 {
  margin-top: 40px !important; }

.u-mb40 {
  margin-bottom: 40px !important; }

.u-ml40 {
  margin-left: 40px !important; }

.u-mr40 {
  margin-right: 40px !important; }

.u-pt40 {
  padding-top: 40px !important; }

.u-pb40 {
  padding-bottom: 40px !important; }

.u-pl40 {
  padding-left: 40px !important; }

.u-pr40 {
  padding-right: 40px !important; }

.u-mt45 {
  margin-top: 45px !important; }

.u-mb45 {
  margin-bottom: 45px !important; }

.u-ml45 {
  margin-left: 45px !important; }

.u-mr45 {
  margin-right: 45px !important; }

.u-pt45 {
  padding-top: 45px !important; }

.u-pb45 {
  padding-bottom: 45px !important; }

.u-pl45 {
  padding-left: 45px !important; }

.u-pr45 {
  padding-right: 45px !important; }

.u-mt50 {
  margin-top: 50px !important; }

.u-mb50 {
  margin-bottom: 50px !important; }

.u-ml50 {
  margin-left: 50px !important; }

.u-mr50 {
  margin-right: 50px !important; }

.u-pt50 {
  padding-top: 50px !important; }

.u-pb50 {
  padding-bottom: 50px !important; }

.u-pl50 {
  padding-left: 50px !important; }

.u-pr50 {
  padding-right: 50px !important; }

.u-mt55 {
  margin-top: 55px !important; }

.u-mb55 {
  margin-bottom: 55px !important; }

.u-ml55 {
  margin-left: 55px !important; }

.u-mr55 {
  margin-right: 55px !important; }

.u-pt55 {
  padding-top: 55px !important; }

.u-pb55 {
  padding-bottom: 55px !important; }

.u-pl55 {
  padding-left: 55px !important; }

.u-pr55 {
  padding-right: 55px !important; }

.u-mt60 {
  margin-top: 60px !important; }

.u-mb60 {
  margin-bottom: 60px !important; }

.u-ml60 {
  margin-left: 60px !important; }

.u-mr60 {
  margin-right: 60px !important; }

.u-pt60 {
  padding-top: 60px !important; }

.u-pb60 {
  padding-bottom: 60px !important; }

.u-pl60 {
  padding-left: 60px !important; }

.u-pr60 {
  padding-right: 60px !important; }

.u-mt65 {
  margin-top: 65px !important; }

.u-mb65 {
  margin-bottom: 65px !important; }

.u-ml65 {
  margin-left: 65px !important; }

.u-mr65 {
  margin-right: 65px !important; }

.u-pt65 {
  padding-top: 65px !important; }

.u-pb65 {
  padding-bottom: 65px !important; }

.u-pl65 {
  padding-left: 65px !important; }

.u-pr65 {
  padding-right: 65px !important; }

.u-mt70 {
  margin-top: 70px !important; }

.u-mb70 {
  margin-bottom: 70px !important; }

.u-ml70 {
  margin-left: 70px !important; }

.u-mr70 {
  margin-right: 70px !important; }

.u-pt70 {
  padding-top: 70px !important; }

.u-pb70 {
  padding-bottom: 70px !important; }

.u-pl70 {
  padding-left: 70px !important; }

.u-pr70 {
  padding-right: 70px !important; }

.u-mt75 {
  margin-top: 75px !important; }

.u-mb75 {
  margin-bottom: 75px !important; }

.u-ml75 {
  margin-left: 75px !important; }

.u-mr75 {
  margin-right: 75px !important; }

.u-pt75 {
  padding-top: 75px !important; }

.u-pb75 {
  padding-bottom: 75px !important; }

.u-pl75 {
  padding-left: 75px !important; }

.u-pr75 {
  padding-right: 75px !important; }

.u-mt80 {
  margin-top: 80px !important; }

.u-mb80 {
  margin-bottom: 80px !important; }

.u-ml80 {
  margin-left: 80px !important; }

.u-mr80 {
  margin-right: 80px !important; }

.u-pt80 {
  padding-top: 80px !important; }

.u-pb80 {
  padding-bottom: 80px !important; }

.u-pl80 {
  padding-left: 80px !important; }

.u-pr80 {
  padding-right: 80px !important; }

.u-mt85 {
  margin-top: 85px !important; }

.u-mb85 {
  margin-bottom: 85px !important; }

.u-ml85 {
  margin-left: 85px !important; }

.u-mr85 {
  margin-right: 85px !important; }

.u-pt85 {
  padding-top: 85px !important; }

.u-pb85 {
  padding-bottom: 85px !important; }

.u-pl85 {
  padding-left: 85px !important; }

.u-pr85 {
  padding-right: 85px !important; }

.u-mt90 {
  margin-top: 90px !important; }

.u-mb90 {
  margin-bottom: 90px !important; }

.u-ml90 {
  margin-left: 90px !important; }

.u-mr90 {
  margin-right: 90px !important; }

.u-pt90 {
  padding-top: 90px !important; }

.u-pb90 {
  padding-bottom: 90px !important; }

.u-pl90 {
  padding-left: 90px !important; }

.u-pr90 {
  padding-right: 90px !important; }

.u-mt95 {
  margin-top: 95px !important; }

.u-mb95 {
  margin-bottom: 95px !important; }

.u-ml95 {
  margin-left: 95px !important; }

.u-mr95 {
  margin-right: 95px !important; }

.u-pt95 {
  padding-top: 95px !important; }

.u-pb95 {
  padding-bottom: 95px !important; }

.u-pl95 {
  padding-left: 95px !important; }

.u-pr95 {
  padding-right: 95px !important; }

.u-mt100 {
  margin-top: 100px !important; }

.u-mb100 {
  margin-bottom: 100px !important; }

.u-ml100 {
  margin-left: 100px !important; }

.u-mr100 {
  margin-right: 100px !important; }

.u-pt100 {
  padding-top: 100px !important; }

.u-pb100 {
  padding-bottom: 100px !important; }

.u-pl100 {
  padding-left: 100px !important; }

.u-pr100 {
  padding-right: 100px !important; }

.anime-opa {
  transition: opacity 0.3s !important; }

.anime-opa:hover {
  opacity: .8; }

.anime-opaimg {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: opacity 0.5s cubic-bezier(0, 0, 0.1, 1);
  will-change: transform; }

.anime-opaimg img,
.anime-opaimg video {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: opacity 0.5s cubic-bezier(0, 0, 0.1, 1), transform 0.5s cubic-bezier(0, 0, 0.1, 1); }

.anime-opaimg:hover {
  opacity: 0.8 !important; }

.anime-opaimg:hover img,
.anime-opaimg:hover video {
  opacity: 0.8 !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: scale(1.05); }

.anime-img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.anime-img img,
.anime-img video {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.5s cubic-bezier(0, 0, 0.1, 1); }

.anime-img:hover img,
.anime-img:hover video {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: scale(1.05); }

.js_opaimg {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative; }

.js_opaimg img,
.js_opaimg video {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: scale(1.05); }

.anime-ponpon {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.anime-ponpon:hover,
.anime-ponpon:focus,
.anime-ponpon:active {
  animation: anime-ponpon .85s ease-in-out 1; }

.js_isshow {
  animation-name: fadeInAnimePC;
  animation-fill-mode: forwards;
  animation-duration: .8s;
  animation-timing-function: liner;
  animation-delay: .1s;
  animation-direction: normal; }

.wpadminbar .alphaon {
  top: 32px !important; }

html {
  font-size: 16px; }

html, body, .index-mv {
  width: 1240px;
  font-size: 16px; }

.main-menu-list,
.sub-menu-list,
a[class*="btn-"],
.index-contents-list a {
  font-size: 16px; }

.index-mv-data h2 {
  font-size: 34px; }

.index-mv-data {
  padding-top: 40px; }

.index-contents-copy,
.list-item span {
  font-size: 20px; }

.index-contents-item h3,
.footer-contact-area h2,
.page-contact-area h2,
.h-large--ul,
.h-large--normal,
.h-index-news,
.h-voice {
  font-size: 26px; }

.index-contents-item p {
  font-size: 14px; }

.footer-contact-area h4, .page-contact-area h4 {
  font-size: 18px; }

.footer-contact-tel {
  font-size: 36px; }

.h-pagetitle {
  font-size: 30px; }

.index-main, .page-main {
  margin-top: 0;
  padding-top: 140px; }

.js_print {
  display: block; }

header,
.fix-header {
  position: absolute; }

.top-btn {
  display: none !important; }

.btn-return:before {
  right: auto; }

.js_fadein,
.event-list-item, .bloglist, .workslist > div, .js_fadein-timeline > div {
  opacity: 1 !important;
  transform: matrix(1, 0, 0, 1, 0, 0) !important; }
