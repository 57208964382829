@import "../printcss/style.min";
//上書き用
html{
font-size: 16px;    
}

html,body,.index-mv{
    width: 1240px;
    font-size: 16px;
}
.main-menu-list,
.sub-menu-list,
a[class*="btn-"],
.index-contents-list a{
font-size: 16px;    
}

.index-mv-data h2{
font-size: 34px; 
}

.index-mv-data{
padding-top: 40px;
}
.index-contents-copy,
.list-item span{
    font-size: 20px;
}
.index-contents-item h3,
.footer-contact-area h2,
.page-contact-area h2,
.h-large--ul,
.h-large--normal,
.h-index-news,
.h-voice{
    font-size: 26px;
}
.index-contents-item p{
    font-size: 14px;
}
.footer-contact-area h4, .page-contact-area h4{
    font-size: 18px; 
}
.footer-contact-tel{
font-size: 36px;    
}
.h-pagetitle{
    font-size: 30px;
}


.index-main, .page-main{
    margin-top: 0;
    padding-top: 140px;
}
   
.js_print{
    display: block;
}
header,
.fix-header{
    position: absolute;
}
.top-btn {
display: none !important;;    
}
.btn-return:before{
right: auto;    
}
body,p,h1,h2,h3,h4,table,span,div{
    //font-family: sans-serif !important;
}
.js_fadein,
.event-list-item,.bloglist,.workslist>div,.js_fadein-timeline>div{
    opacity: 1 !important;
    transform: matrix(1, 0, 0, 1, 0, 0) !important;
}
